<template>
  <v-container fill-height justify-content-start :class="{'pa-2': $vuetify.breakpoint.xsOnly}">
    <v-layout column>
      <v-layout row wrap justify-start class="ma-0 max-height-100">
        <v-flex xs9 class="max-height-100">
          <v-layout column class="pa-4 max-height-100 overflow-y-auto">
            <!-- TITLE -->
            <v-layout wrap align-center class="flex-none">
              <v-btn :class="{'ma-1': xsOnly}" :fab="mdAndUp" icon text @click="$router.go(-1)">
                <v-icon color="grey" :large="mdAndUp">mdi-arrow-left</v-icon>
              </v-btn>
              <h2 class="display-2 raleway grey--text mt-3 mb-4">{{$t('packages.top_up_packages')}}</h2>
            </v-layout>
            <shift-required>
              <v-layout row class="wrap-none">
                <v-container>
                  <v-layout column class="ma-3">
                    <!-- GUEST INFO -->
                    <v-layout v-if="guest" wrap class="my-3" align-center>
                      <span class="grey--text mr-2 display-1">{{ getGuestFullName(guest) }}</span>
                      <v-chip v-if="booking" class="subtitle-1 my-1 mr-2" label>
                        <v-icon class="mr-2">{{ isParkBooking(booking) ? 'mdi-ticket' : 'mdi-bed' }}</v-icon>
                        <span>{{ isParkBooking(booking) ? $t('bookings.park_visitor') : $t('bookings.hotel_guest') }}</span>
                      </v-chip>
                    </v-layout>
                    <!-- SEARCH & RESET -->
                    <v-layout wrap class="my-3" justify-space-between align-center>
                      <v-flex xs12 sm6 md5 lg4>
                        <v-text-field
                          v-model="search"
                          :label="$t('packages.search')"
                          append-icon="mdi-magnify"
                          color="primary"
                          hide-details
                          class="mb-1"
                          clearable
                          outlined
                        />
                      </v-flex>
                      <v-btn v-if="cartPackages.length" outlined color="primary" @click="$store.dispatch('packageOrder/clearOrder')">
                        {{ $t('actions.reset_purchase') }}
                      </v-btn>
                    </v-layout>
                    <!-- PACKAGES -->
                    <package-list
                      :search="search ? sanitize(search) : null"
                      :extendedAccount="guest ? guest.extendedAccount : null"
                      :list="cartPackages"
                      storeModule="packageOrder"
                      style="max-height: 412px;"
                    />
                  </v-layout>
                </v-container>
                <!-- SUMMARY -->
                <order-summary
                  class="flex-none"
                  storeModule="packageOrder"
                  :enableCancelButton="!!cartPackages.length"
                  :enableContinueButton="!!cartPackages.length && !loading.book"
                  :loading="loading.book"
                  :extendedAccount="guest ? guest.extendedAccount : null"
                  :orderLines="packageOrderLines"
                  @cancel="$store.dispatch('packageOrder/clearOrder')"
                  @continue="confirmModal.triggerOpen = new Date()"
                  :includeRoomCharge="availableRoomCharge"
                />
              </v-layout>
            </shift-required>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-layout>
    <payment-confirm-modal
      :currency="currency"
      :paymentMethodId="paymentMethodId"
      :total="totalAmount"
      :triggerOpen="confirmModal.triggerOpen"
      @accept="completeOrder()"
      @closedAfterAccept="exit()"
      @closed="resetErrors()"
      :completedAction="!!orderId"
    >
      <template v-slot:errors>
        <v-card-actions v-if="errors.paymentError">
          <v-layout column>
            <div class="body-2 text-center"><v-icon color="error" class="mr-2">mdi-alert-circle-outline</v-icon>{{$t('ticket_order.error.payment')}}</div>
            <v-layout row justify-center class="ma-0">
              <v-btn text color="primary" @click="completeOrder()" class="mt-3">{{$t('actions.retry')}}</v-btn>
            </v-layout>
          </v-layout>
        </v-card-actions>
        <v-card-actions v-if="errors.ticketGenerationError || errors.printingTicketError">
          <v-layout column>
            <div class="body-2 text-center"><v-icon color="warning" class="mr-2">mdi-alert-outline</v-icon>{{$t('ticket_order.error.receipt')}}</div>
            <v-layout row justify-center class="ma-0">
              <v-btn text v-if="errors.ticketGenerationError" color="primary" @click="getAndPrintPurchaseTicket()" class="mt-3">{{$t('actions.retry')}}</v-btn>
              <v-btn text v-if="errors.printingTicketError" color="primary" @click="printOrderTicket()" class="mt-3">{{$t('actions.retry')}}</v-btn>
            </v-layout>
          </v-layout>
        </v-card-actions>
        <v-layout v-if="loading.complete || loading.getTicket || loading.print" align-center justify-center class="my-4 grey--text body">
          <i>{{
            loading.complete
              ? $t('package_order.loading.complete')
              : loading.getTicket
                ? $t('package_order.loading.ticket')
                : $t('package_order.loading.print')
          }}</i><loading-dots />
        </v-layout>
      </template>
    </payment-confirm-modal>
  </v-container>
</template>
<script>
import { debounce } from 'lodash'
import routeNames from '@/router/routes'
import { mapState, mapGetters } from 'vuex'
import { getGuestFullName } from '@/utils/GuestUtils'
import { OrderRequests } from '@/services/order.requests'
import { isHotelGuest, postpaidBaseCurrency } from '@/utils/GuestUtils'


import searchMixins from '@/mixins/Search'
import vuetifyMixins from '@/mixins/Vuetify'
import permissionMixins from '@/mixins/Permission'
import pricingHelpers from '@/mixins/PricingHelpers'
import localizationMixins from '@/mixins/Localization'
import peripheralMixins from '@/mixins/PeripheralSocket'
import printerMixins from '@/mixins/PrinterPeripheralActions'

const orderService = new OrderRequests()

export default {
  data () {
    return {
      getGuestFullName,
      isHotelGuest,
      postpaidBaseCurrency,
      currentVisitorKey: 0,
      confirmModal: {
        triggerOpen: null,
        triggerClose: null
      },
      debounceBook: null,
      orderId: null,
      bookingId: null,
      base64Ticket: null,
      errors: {
        paymentError: false,
        ticketGenerationError: false,
        printingTicketError: false
      },
      loading: {
        complete: false,
        getTicket: false,
        print: false,
        book: false
      }
    }
  },
  computed: {
    ...mapGetters({
      currencies: 'configuration/currencies',
      pricingGroups: 'configuration/pricingGroups',
      isParkBooking: 'configuration/isParkBooking',
      cashPaymentMethodId: 'configuration/cashPaymentMethodId',
      getBookedPackages: 'packageOrder/getBookedPackages',
      getPackage: 'packages/getPackage',
      getPackageAmount: 'packageOrder/getPackageAmount'
    }),
    ...mapState({
      guest: state => state.guests.read,
      packages: state => state.packages.list,
      guestId: state => state.packageOrder.guestId,
      currencyId: state => state.packageOrder.currencyId,
      totalAmount: state => state.packageOrder.totalAmount,
      packageOrderErrors: state => state.packageOrder.errors,
      cartPackages: state => state.packageOrder.cartPackages,
      pricingGroupId: state => state.packageOrder.pricingGroupId,
      bookedPackages: state => state.packageOrder.bookedPackages,
      paymentMethodId: state => state.packageOrder.paymentMethodId
    }),
    packageOrderLines () {
      var orderLines = []
      this.cartPackages.forEach((cartPackage) => {
        const pkg = this.packages.find((item) => item.id === cartPackage.packageId)
        if (!pkg) return
        const price = this.getPrice(pkg.pricingGroupsWithPrices, this.pricingGroupId)
        orderLines.push({
          name: this.localize({ array: pkg.name }),
          quantity: cartPackage.quantity,
          amount: price ? price.price : null
        })
      })

      return orderLines
    },
    currency () {
      return this.currencyId ? this.currencies.find((c) => c.id === this.currencyId) : null
    },
    booking () {
      return this.guest ? this.guest.booking : null
    },
    mustBeBookedCartPackages () {
      return this.cartPackages.filter(({ packageId}) => {
        const pkg = this.getPackage({ id: packageId })
        return pkg && pkg.mustBeBooked
      })
    },
    canCompleteOrder () {
      return !!this.cartPackages.length && (!!this.paymentMethodId || this.availableRoomCharge)
    },
    availableRoomCharge () {
      return this.isHotelGuest(this.guest) && this.postpaidBaseCurrency(this.guest)
    }
  },
  methods: {
    completeOrder () {
      this.errors.paymentError = false
      this.loading.complete = true
      this.triggerBook({ packages: this.mustBeBookedCartPackages }).then(() => {
        this.$store.dispatch('packageOrder/complete')
          .then((orderId) => {
            this.orderId = orderId
            this.getAndPrintPurchaseTicket()
            this.$store.commit('state/setMessage', { text: this.$t('ticket_order.success'), color: 'success' })
          }).catch((error) => {
            this.errors.paymentError = true
          }).finally(() => {
            this.loading.complete = false
          })
      }).catch((error) => {
        this.loading.complete = false
      })
    },
    triggerBook ({ packages }) {
      const promises = packages.map(({ packageId }) => {
        const pkg = this.getPackage({ id: packageId })
        const quantity = this.getPackageAmount(packageId)
        this.$store.commit(`packageOrder/deleteBookingErrorPackages`, packageId)
        return this.$store.dispatch('packageOrder/book', { productId: packageId, quantity })
          .then(() => {
            this.$store.commit(`packageOrder/deleteBookingErrorPackages`, packageId)
          })
          .catch(err => {
            if (err.body && err.body.message && err.body.message.includes('{quantity=')) {
              this.$store.commit(`packageOrder/setBookingErrorPackages`, packageId)
            }
          })
      })
      return Promise.all(promises).finally(() => {
        this.$store.dispatch('packages/list')
        this.loading.book = false
      })
    },
    getAndPrintPurchaseTicket () {
      this.loading.getTicket = true
      this.errors.ticketGenerationError = false
      orderService.getTicket(this.orderId).then((base64Pdf) => {
        this.base64Ticket = base64Pdf
        this.printOrderTicket()
      }).catch(() => {
        this.errors.ticketGenerationError = true
      }).finally(() => {
        this.loading.getTicket = false
      })
    },
    printOrderTicket () {
      this.errors.printingTicketError = false
      this.loading.print = true
      this.sendPrinterAction(this.base64Ticket, 'application/pdf', () => {
        if (this.paymentMethodId !== this.cashPaymentMethodId) {
          this.exit()
        }
        this.loading.print = false
      }, () => {
        this.errors.printingTicketError = true
        this.loading.print = false
      })
    },
    exit () {
      if (!this.errors.paymentError) {
        this.$router.push({ name: routeNames.ticket.name, params: { id: this.booking.id } })
      }
    },
    resetErrors () {
      this.errors.paymentError = false
      this.errors.ticketGenerationError = false
      this.errors.printingTicketError = false
    }
  },
  watch: {
    mustBeBookedCartPackages: {
      deep: true,
      handler: function (packages, oldPackages) {
        const deletedPackages = oldPackages.filter(({ packageId }) => {
          return !packages.find((cartPackage) => cartPackage.packageId === packageId)
        })
        if (JSON.stringify(packages) !== JSON.stringify(oldPackages)) {
          this.loading.book = true
          this.debounceBook({ packages: [...packages, ...deletedPackages] })
        }
      }
    }
  },
  created () {
    this.debounceBook = debounce(this.triggerBook, 800)
    if (!this.guestId) {
      this.$router.push({ name: routeNames.ticket_validation.name })
      return
    }
    this.$store.dispatch('guests/get', { id: this.guestId, save: true, decorate: true }).then((guest) => {
      this.$store.commit('packageOrder/setPricingGroupId', guest.extendedAccount.account.pricingGroupId)
      this.$store.commit('packageOrder/setAccountId', guest.extendedAccount.account.id)
    })
    this.$store.dispatch('packages/list').then(() => {
      this.triggerBook({ packages: this.mustBeBookedCartPackages })
    })
  },
  beforeDestroy () {
    this.$store.dispatch('packageOrder/clearOrder', true)
  },
  mixins: [
    searchMixins,
    printerMixins,
    vuetifyMixins,
    pricingHelpers,
    peripheralMixins,
    permissionMixins,
    localizationMixins
  ],
  components: {
    ShiftRequired: () => import('@/components/view-components/ShiftRequired.vue'),
    OrderSummary: () => import('@/components/view-components/OrderSummary.vue'),
    PackageList: () => import('@/components/view-components/package-order/PackageList.vue'),
    PaymentConfirmModal: () => import('@/components/view-components/modals/PaymentConfirm.vue'),
    LoadingDots: () => import('@/components/app-components/LoadingDots.vue')
  }
}
</script>
